
<template lang="html">
    <div>
        <vx-card actionable class="cardx" >
            <vs-row vs-w="12">
                <vs-col vs-w="3"><h5>Stats By BDE</h5></vs-col>
                <vs-col vs-w="3"></vs-col>
                <vs-col vs-w="3"></vs-col>
                <vs-col vs-w="3">
                    <v-select label="countryName" v-model="selectedOption" :options="flaging_delays"></v-select>
                </vs-col>
            </vs-row><br>   
            <div>         
            <vs-table  max-items="10" :data="bdes">
                <template slot="thead" class="exc" slot-scope="{data}">
                    <vs-th >
                    BDE
                    </vs-th>
                    <vs-th >
                    M6
                    </vs-th>
                    <vs-th >
                    M5
                    </vs-th>
                    <vs-th>
                    M4
                    </vs-th>
                    <vs-th>
                    M3++
                    </vs-th>
                    <vs-th>
                    M3+
                    </vs-th>
                    <vs-th>
                    M3
                    </vs-th>
                    <vs-th>
                    M2
                    </vs-th>
                    <vs-th>
                    L5
                    </vs-th>
                    <vs-th>
                    L4
                    </vs-th>
                    <vs-th>
                    L4-
                    </vs-th>
                    <vs-th>
                    L3+
                    </vs-th>
                    <vs-th>
                    L3
                    </vs-th>
                    <vs-th>
                    L2
                    </vs-th>
                    <vs-th>
                    L2-
                    </vs-th>
                    <vs-th>
                    Total
                    </vs-th>
                </template>

                <template slot-scope="{data}">
                    <vs-tr  :key="indextr" v-for="(tr, indextr) in data" >
                        <vs-td  :data="data[indextr].cities" >
                            {{data[indextr].cities}}
                        </vs-td>
                        <vs-td  :data="data[indextr].M6" >
                            {{data[indextr].M6}}
                        </vs-td>  
                        <vs-td :data="data[indextr].M5">
                            {{data[indextr].M5}}
                        </vs-td>
                        <vs-td :data="data[indextr].M4">
                            {{data[indextr].M4}}
                        </vs-td>
                        <vs-td :data="data[indextr].M3plus">
                            {{data[indextr].M3plus}}
                        </vs-td>
                        <vs-td :data="data[indextr].M3">
                            {{data[indextr].M3}}
                        </vs-td>
                        <vs-td :data="data[indextr].M2">
                            {{data[indextr].M2}}
                        </vs-td>
                        <vs-td :data="data[indextr].L5">
                            {{data[indextr].L5}}
                        </vs-td>
                        <vs-td :data="data[indextr].L4">
                            {{data[indextr].L4}}
                        </vs-td>
                        <vs-td :data="data[indextr].L4minus">
                            {{data[indextr].L4minus}}
                        </vs-td>
                        <vs-td :data="data[indextr].L3plus">
                            {{data[indextr].L3plus}}
                        </vs-td>
                        <vs-td :data="data[indextr].L3">
                            {{data[indextr].L3}}
                        </vs-td>
                        <vs-td :data="data[indextr].L2">
                            {{data[indextr].L2}}
                        </vs-td>
                        <vs-td :data="data[indextr].L2minus">
                            {{data[indextr].L2minus}}
                        </vs-td>
                        <vs-td :data="data[indextr].Total">
                            {{data[indextr].Total}}
                        </vs-td>
                    </vs-tr>
                </template>
            </vs-table>  
            </div>
        </vx-card>
    </div>  
</template> 
<script>
    import vSelect from 'vue-select';
    export default {
        data:()=>({
        selectedOption: '',
        bdes: [],
        hydbdes:[
            { 
                "cities":"Dev",
                "M6": 200,
                "M5": 180,
                "M4": 160,
                "M3plus": 140,
                "M3": 223,
                "M2": 200,
                "L5": 60,
                "L4": 120,
                "L4minus": 1410,
                "L3plus": 119,
                "L3":1121,
                "L2":2180,
                "L2minus":5307,
                "Total":9381
            },
            { "cities":"Harsh",
                "M6": 234,
                "M5": 123,
                "M4": 983,
                "M3plus": 128,
                "M3": 1231,
                "M2": 125,
                "L5": 345,
                "L4": 234,
                "L4minus": 26,
                "L3plus": 235,
                "L3":9,
                "L2":235,
                "L2minus":1245,
                "Total":23345
            },
            {
                "cities":"Preeti",
                "M6": 200,
                "M5": 180,
                "M4": 160,
                "M3plus": 140,
                "M3": 23,
                "M2": 200,
                "L5": 600,
                "L4": 140,
                "L4minus": 110,
                "L3plus": 19,
                "L3":51,
                "L2":2280,
                "L2minus":5307,
                "Total":9181
            },
            { 
                "cities":"John Carter",
                "M6": 200,
                "M5": 180,
                "M4": 160,
                "M3plus": 140,
                "M3": 223,
                "M2": 200,
                "L5": 60,
                "L4": 120,
                "L4minus": 1410,
                "L3plus": 119,
                "L3":1121,
                "L2":2180,
                "L2minus":5307,
                "Total":9381
            }
        ],
        bngbdes:[
            { "cities": "Karan",
                "M6": 234,
                "M5": 123,
                "M4": 983,
                "M3plus": 128,
                "M3": 1231,
                "M2": 125,
                "L5": 345,
                "L4": 234,
                "L4minus": 26,
                "L3plus": 235,
                "L3":9,
                "L2":235,
                "L2minus":1245,
                "Total":23345
            },
            {
                "cities":"Ankita",
                "M6": 200,
                "M5": 180,
                "M4": 160,
                "M3plus": 140,
                "M3": 23,
                "M2": 200,
                "L5": 600,
                "L4": 140,
                "L4minus": 110,
                "L3plus": 19,
                "L3":51,
                "L2":2280,
                "L2minus":5307,
                "Total":9181
            },
            { 
                "cities":"Jayendra",
                "M6": 200,
                "M5": 180,
                "M4": 160,
                "M3plus": 140,
                "M3": 223,
                "M2": 200,
                "L5": 60,
                "L4": 120,
                "L4minus": 1410,
                "L3plus": 119,
                "L3":1121,
                "L2":2180,
                "L2minus":5307,
                "Total":9381
            },
            { "cities":"Amit",
                "M6": 234,
                "M5": 123,
                "M4": 983,
                "M3plus": 128,
                "M3": 1231,
                "M2": 125,
                "L5": 345,
                "L4": 234,
                "L4minus": 26,
                "L3plus": 235,
                "L3":9,
                "L2":235,
                "L2minus":1245,
                "Total":23345
            },
            {
                "cities":"Pooja",
                "M6": 200,
                "M5": 180,
                "M4": 160,
                "M3plus": 140,
                "M3": 23,
                "M2": 200,
                "L5": 600,
                "L4": 140,
                "L4minus": 110,
                "L3plus": 19,
                "L3":51,
                "L2":2280,
                "L2minus":5307,
                "Total":9181
            },
            { 
                "cities":"Nida",
                "M6": 200,
                "M5": 180,
                "M4": 160,
                "M3plus": 140,
                "M3": 223,
                "M2": 200,
                "L5": 60,
                "L4": 120,
                "L4minus": 1410,
                "L3plus": 119,
                "L3":1121,
                "L2":2180,
                "L2minus":5307,
                "Total":9381
            }
        ],
        flaging_delays: [ "Bangalore", "Hyderabad" ]
    }),
    mounted() {
        this.sortingMethod()
    },
    watch: {
        selectedOption: function(value) {
            this.bdes = []
            switch(value) {
                case 'Bangalore':
                    this.selectedOption = 'Bangalore'
                    this.bngbdes.forEach((base) => {
                        this.bdes.push(base)
                    })
                    break
                case 'Hyderabad':
                    this.selectedOption = 'Hyderabad'
                    this.hydbdes.forEach((base) => {
                        this.bdes.push(base)
                    })
            }
        }
    },
    methods: {
        sortingMethod() {
            this.bdes = []
            console.log(this.$store.state.StatByBde)
            switch(this.$store.state.StatByBde) {
                case 'Bangalore':
                    this.selectedOption = 'Bangalore'
                    this.bngbdes.forEach((base) => {
                        this.bdes.push(base)
                    })
                    break
                case 'Hyderabad':
                    this.selectedOption = 'Hyderabad'
                    this.hydbdes.forEach((base) => {
                        this.bdes.push(base)
                    })
            }
        }
    },
    components: {
        'v-select': vSelect,
            // VueApexCharts,
            // StatisticsCardLine,
            // ChangeTimeDurationDropdown
                    // ChangeTimeDurationDropdown

        },
    }
</script>
<style>
/* .vs-radar-text{
    color: darkgray;
    position: relative;
    bottom: 70px;
    left: 35px;
    font-size: larger;
}
.radial_quarter{
    position: relative;
    height: 260px;
}
.lineAreaChartSpline{
    position: relative;
    height: 260px;
}
.vs-radar-thisquarter{
    color: darkgray;
    position: relative;
    bottom: 70px;
    font-size: larger;
    text-align: center;
}
.input-search{
      position: relative;
    right: 10px;
}
.table_heading{
  left: 20px;
  position: relative;
}
.vs-table--tbody-table tr{
  font-family: sans-serif;
  font-size: smaller;  
} */
</style>

